import React, { useState, useContext } from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { UrlContext } from '../context/UrlContext';
import { useLanguage } from "../context/LanguageContext";

function OccupationCriteria() {
    const [selectedCriteria, setSelectedCriteria] = useState('T');
    const ServerUrl = useContext(UrlContext);
    const { /* language, */ translations } = useLanguage();

    const handleChange = (event) => {
      setSelectedCriteria(event.target.value);
    };

    async function SelectedOccupationCriteria() {
        const CriteriaData = await fetch(`${ServerUrl}/SelectedOccupationCriteria`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ SelectedOccupationCriteria: selectedCriteria })
        });
        return await CriteriaData.json();  
    };
  
    return (
      SelectedOccupationCriteria(),
      <RadioGroup className='occupationCriteria' value={selectedCriteria} onChange={handleChange}>
        <FormControlLabel value="T" control={<Radio />} label={translations.WeightedOccupation} />
        <FormControlLabel value="F" control={<Radio />} label={translations.MaximumOccupancy} />
      </RadioGroup>
    );
  }

export default OccupationCriteria