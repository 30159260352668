import { useState, useEffect, useContext } from "react";
import { UrlContext } from "../context/UrlContext";
import { Chart } from 'react-google-charts';

//import { useLanguage } from "../context/LanguageContext";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import useStorage from "../hooks/useStorage";


function Terminations() {
    const [fetchedCode, setFetchedCode] = useState("");
    const [fetchedMsg, setFetchedMsg] = useState("");
    const [fetchedTitle, setFetchedTitle] = useState("");
    const [fetchedRANGE_OF_DATES, setFetchedRANGE_OF_DATES] = useState("");
    const [fetchedTOT_MSH, setFetchedTOT_MSH] = useState("");
    const [fetchedTgtReg, setFetchedTgtReg] = useState("");
    const [data, setData] = useState([]);
    const [fetchedTotClubs, setFetchedTotClubs] = useState("");
    const ServerUrl = useContext(UrlContext);
    const storage = useStorage()

   // const [fetchedRegToday, setFetchedRegToday] = useState("");
  
    /*const [fetchedTgtReg, setFetchedTgtReg] = useState("");
    const [fetchedTotCustomers, setFetchedTotCustomers] = useState("");
    const [fetchedTotClubs, setFetchedTotClubs] = useState("");
    const [fetchedExDrpt, setFetchedExDrpt] = useState("");
    const [fetchedTotRegFst, setFetchedTotRegFst] = useState("");
    const [fetchedTotReqDpt, setFetchedTotReqDpt] = useState("");
   
   */
   // const { /* language, */ translations } = useLanguage();
   const chartData = [['Drop Out Reason', 'Total']];
   console.log(data)
   if (data != undefined) {
    data.forEach((item) => {
      chartData.push([item.DROP_OUT_RSN_DESCRIPTION, item.TOT_DROP_OUT_RSN]);
    });
  
   }
  
  useEffect(() => {
    
    if (!storage) return;

    let ClubToCheck = storage.getItem('selectedClub');
    let StartDateToCheck = storage.getItem('startDate');
    let EndDateToCheck = storage.getItem('endDate');
    const LangToCheck = localStorage.getItem('language');;
    const storedClub=storage.getItem('storedClubs') 
    if (!ClubToCheck) { // let ClubToCheck = localStorage.getItem('selectedClub');
   if (storedClub) {
    const parsedStoredClub = JSON.parse(storedClub);
  
    if (Array.isArray(parsedStoredClub) && parsedStoredClub.length > 0) {
     // console.log(parsedStoredClub[0].CLUB_CODE);
     ClubToCheck =  parsedStoredClub[0].CLUB_CODE;
    } else {
      console.log("Empty or incorrect array.");
    }
  } else {
    console.log("storedClub undefined.");
  }
   
  }
   // console.log(ClubToCheck);
   if (!EndDateToCheck) {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    EndDateToCheck = formattedDate;
  }
  
  //console.log(EndDateToCheck); 
  if (!StartDateToCheck) {
    const today = new Date();
    today.setMonth(today.getMonth() - 1); 
    const formattedDate = today.toISOString().split('T')[0];
    StartDateToCheck = formattedDate;
  }
  
  //console.log(StartDateToCheck); 
   /* console.log(ClubToCheck);
  console.log(EndDateToCheck);
  console .log(StartDateToCheck);
  console.log(LangToCheck);*/
    const url = `${ServerUrl}/api_Terminations?pCLUB_CODE=${ClubToCheck}&pDATE_FROM=${StartDateToCheck}&pDATE_TO=${EndDateToCheck}&pLANG_CODE=${LangToCheck}`;
  
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES, TOT_MSH, TgtReg, DROPOUT_RSN_LIST } = data;
        setFetchedCode(MESSAGE_CODE);
        setFetchedMsg(MESSAGE);
        setFetchedTitle(PAGE_TITLE);
        setFetchedTotClubs(CLUB_NAME);
        setFetchedRANGE_OF_DATES(RANGE_OF_DATES);
        setFetchedTOT_MSH(TOT_MSH);
        setFetchedTgtReg(TgtReg);
        setData(DROPOUT_RSN_LIST);
      });
  }, [ServerUrl,storage]);
  
   
   if ( fetchedCode === 0 ) {
      return (
       <div className="pageTitle">

        <h1>{fetchedTitle}</h1>
        <p className="ClubName">{fetchedTotClubs}</p>
        <p className="DatesRange">{fetchedRANGE_OF_DATES}</p>
                
                <Chart
              
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={{
                  title: '',
                  width: '100%',
                  height: 550, 
                }}
              />
        
 
       </div>
      )
   }
     else {
       return (
       <>
        <h5>not found</h5>
       <h4>{fetchedCode}{fetchedMsg}</h4>
       </>
       )
    }
  }

export default Terminations