import { useState, useEffect, useContext } from "react";
import ApiGender from "./apiAccess/ApiGender";
import { UrlContext } from "../context/UrlContext";
import { useLanguage } from "../context/LanguageContext";
import "../assets/styles/loading.css";

function AccessByGender() {

  const [fetchedCode, setFetchedCode] = useState([]);
  const [fetchedMsg, setFetchedMsg] = useState([]);
  const [fetchedTitle, setFetchedTitle] = useState([]);
  const [fetchedClub, setFetchedClub] = useState([]);
  const [fetchedDates, setFetchedDates] = useState([]);
  const [fetchedMembership, setFetchedMembership] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const { /* language, */ translations } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${ServerUrl}/api_AccessByGender`)
      .then(response => response.json())
      .then(data => {
        const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES, TOTAL_ACTIVE_MEMBERSHIP } = data;
        setFetchedCode(MESSAGE_CODE);
        setFetchedMsg(MESSAGE);
        setFetchedTitle(PAGE_TITLE);
        setFetchedClub(CLUB_NAME);
        setFetchedDates(RANGE_OF_DATES);
        setFetchedMembership(TOTAL_ACTIVE_MEMBERSHIP);
        setIsLoading(false);
      });
  }, [ServerUrl]); 

  if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }
  else if ( fetchedCode === '0' ) {
   return (
    <div>
        <div className="pageTitle">
        <h1>{fetchedTitle}</h1>
        <p className="ClubName">{fetchedClub}</p>
        <p className="DatesRange">{fetchedDates}</p>
        <p className="TotalMembership">Nombre de clients: {fetchedMembership}</p>
        </div>
    <ApiGender />
    </div>
   )
  }
  else if ( fetchedCode !== '0' ) {
    return (
    <>
    <h4>{fetchedMsg}</h4>
    </>
    )
  }
}

export default AccessByGender;