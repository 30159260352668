import { useState, useEffect, useContext } from "react";
import { UrlContext } from "../context/UrlContext";
import { useLanguage } from "../context/LanguageContext";
import "../assets/styles/loading.css";

function PandLDesktop() {
  const [fetchedCode, setFetchedCode] = useState("");
  const [fetchedMsg, setFetchedMsg] = useState("");
  const [fetchedTitle, setFetchedTitle] = useState("");
  const [fetchedDates, setFetchedDates] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const { /* language, */ translations } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${ServerUrl}/api_PandL?source=PandLDesktop`)
      .then(response => response.json())
      .then(data => {
        const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, RANGE_OF_DATES, CLUB_DATA_ALL } = data;
        setFetchedCode(MESSAGE_CODE);
        setFetchedMsg(MESSAGE);
        setFetchedTitle(PAGE_TITLE);
        setFetchedDates(RANGE_OF_DATES);
        setFetchedData(CLUB_DATA_ALL);
        setIsLoading(false);
      });
  }, [ServerUrl]); 

  if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }
  else if ( fetchedCode === 0 ) {
    return (
     <div>
         <div className="pageTitle">
         <h1>{fetchedTitle}</h1>
         <p className="DatesRange">{fetchedDates}</p>
         </div>
         <div className="FixedHeaderTable">
         <table className="PandLTable">
         <thead>
                <tr>
                    <th>{translations.pnlClub}</th>
                    <th>{translations.pnlConcept}</th>
                    <th>{translations.pnlToday}</th>
                    <th>{translations.pnlFromFirst}</th>
                    <th>{translations.pnlTarget}</th>
                </tr>
            </thead>
          <tbody>
            {fetchedData.map((clubData) =>
              clubData.CLUB_DATA.map((item, index) => (
                <tr key={index}>
                  <td>{item.CLUB_NAME}</td>
                  <td>{item.LABEL}</td>
                  <td>{item.DATA1}</td>
                  <td>{item.DATA2}</td>
                  <td>{item.DATA3}</td>
                </tr>
              ))
            )}
        </tbody>
         </table>
         </div>
     </div>
    )
   }
   else {
     return (
     <>
     <h4>{fetchedMsg}</h4>
     </>
     )
   }
}

export default PandLDesktop