import React from 'react';

const NewContracts = () => {
    return (
        <div>
            <h1>Nous contractes</h1>
        </div>
    );
};

export default NewContracts;