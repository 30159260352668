/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ClubSelection from "../components/ClubSelection";
import DatesSelection from "../components/DatesSelection";
import OccupationCriteria from "../components/OccupationCriteria";
import { useLanguage } from "../context/LanguageContext";

function Home() {
  
  const { /* language, */ translations } = useLanguage();

  return (
    <>
    <div className="pageTitle">
    <h1>{translations.ParamSelector}</h1>
    </div>
      <div className="layout-content">
      <ClubSelection />
      <DatesSelection />
      <OccupationCriteria />
      </div>
    </>
  );
}

export default Home;
