// LanguageContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem("language") || "ENG");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    let hasUnMounted = false;
    const validLanguages = ["ENG", "CAT", "ESP", "FRA", "SLV", "POL"];
    const storedLanguage = localStorage.getItem("language");

    const selectedLanguage = validLanguages.includes(storedLanguage) ? storedLanguage : "ENG";

    import(`../assets/lang/${selectedLanguage}.json`)
      .then((translationData) => {
        if(hasUnMounted) {
          return;
        }
        console.log(translationData);
        setTranslations(translationData.default);
        setLanguage(selectedLanguage);
      })
      .catch((error) => {
        console.error(`Error loading translation data: ${error}`);
      });

    return () => {
      hasUnMounted = true;
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
