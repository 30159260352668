import React from 'react';
import Terminations from '../components/Terminations';
const Resiliations = () => {
    return (
        <div>
        
            <Terminations/>

        </div>
    );
};

export default Resiliations;