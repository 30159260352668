import React, { useEffect, useState, useContext } from "react";
import ToggleButton from 'react-toggle';
import 'react-toggle/style.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { UrlContext } from '../../context/UrlContext';

function ApiAge() {

    const [fetchedData, setFetchedData] = useState([]);
    const ServerUrl = useContext(UrlContext);
    const [lineVisibility, setLineVisibility] = useState({
      "RANGE_YEARS 0-15": true,
      "RANGE_YEARS 16-25": true,
      "RANGE_YEARS 26-40": true,
      "RANGE_YEARS 41-55": true,
      "RANGE_YEARS 56-64": true,
      "RANGE_YEARS 65-999": true,
    });

    useEffect(() => {
      fetch(`${ServerUrl}/api_AccessByAge`)
        .then(response => response.json())
        .then(data => setFetchedData(data.ACCESS_DATA));
    },[fetchedData,ServerUrl]);

  return (
    <div className="ageContent">
    <div className="check-0-15">
    <ToggleButton
      id="toggle-0-15"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 0-15": !prevState["RANGE_YEARS 0-15"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 0-15"]}
    />
    </div>
    
    <div className="check-16-25">
    <ToggleButton
      id="toggle-16-25"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 16-25": !prevState["RANGE_YEARS 16-25"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 16-25"]}
    />
    </div>
    
    <div className="check-26-40">
    <ToggleButton
      id="toggle-26-40"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 26-40": !prevState["RANGE_YEARS 26-40"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 26-40"]}
    />
    </div> 
	    
    <div className="check-41-55">
    <ToggleButton
      id="toggle-41-55"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 41-55": !prevState["RANGE_YEARS 41-55"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 41-55"]}
    />
    </div> 
	    
    <div className="check-56-64">
    <ToggleButton
      id="toggle-56-64"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 56-64": !prevState["RANGE_YEARS 56-64"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 56-64"]}
    />
    </div> 
	    
    <div className="check-65-999">
    <ToggleButton
      id="toggle-65-999"
      onChange={() =>
        setLineVisibility((prevState) => ({
          ...prevState,
          "RANGE_YEARS 65-999": !prevState["RANGE_YEARS 65-999"],
        }))
      }
      checked={lineVisibility["RANGE_YEARS 65-999"]}
    />
    </div> 

      <ResponsiveContainer height={400}>
        <LineChart
          data={fetchedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="HOUR" />
          <YAxis />
          <Tooltip />
          <Legend />
          
          <Line dataKey="RANGE_YEARS 0-15" name="0-15" fill="#043bd7" stroke="#043bd7" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 0-15"]} />
          <Line dataKey="RANGE_YEARS 16-25" name="16-25" fill="#dbc108" stroke="#dbc108" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 16-25"]} />
          <Line dataKey="RANGE_YEARS 26-40" name="26-40" fill="#5dbb87" stroke="#5dbb87" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 26-40"]} />
          <Line dataKey="RANGE_YEARS 41-55" name="41-55" fill="#8f4a4a" stroke="#8f4a4a" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 41-55"]} />
          <Line dataKey="RANGE_YEARS 56-64" name="56-64" fill="#525252" stroke="#525252" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 56-64"]} />
          <Line dataKey="RANGE_YEARS 65-999" name="65-999" fill="#db8317" stroke="#db8317" isAnimationActive={false} hide={!lineVisibility["RANGE_YEARS 65-999"]} />
        </LineChart>
      </ResponsiveContainer>    
    </div>
  )
}

export default ApiAge