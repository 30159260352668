/*!
=========================================================
* Eurofitness P&L Tracking - v1.0.0
=========================================================
* Developed by Kennwort C&T
=========================================================
*/
import React, { useEffect, useState, useContext, Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Main from "./components/layout/Main";
import Dashboard from './components/Dashboard';
import Reservations from "./pages/Reservations";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Facturation from "./pages/Facturation";
import ActivClients from "./pages/ActivClients";
import NewContracts from "./pages/NewContracts";
import Resiliations from "./pages/Resiliations";
import CoursNage from "./pages/CoursNage";
import LoginForm from './components/LoginForm';
import AccessByGender from './components/AccessByGender';
import Logout from './components/login/Logout';
import AccessByAge from './components/AccessByAge';
import ActivitiesOccupancy from './components/occupancy/ActivitiesOccupancy';
import { UrlContext } from './context/UrlContext';
import PoolOccupancyPage from './pages/PoolOccupancyPage';
import ProfitAndLoses from './components/ProfitAndLoses';
import PandLDesktop from './components/PandLDesktop';
import CustomerBalance from './components/CustomerBalance';
import CustomerBalanceDsk from './components/CustomerBalanceDsk';
import { CollectionsBookmarkOutlined } from '@material-ui/icons';

function App() {

  const ServerUrl = useContext(UrlContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [tokenMsg, settokenMsg] = useState('');
  const [storedAccess, setstoredAccess] = useState([]);
  const [storedClubs, setstoredClubs] = useState([]);
  const [userLogged, setuserLogged] = useState(false);




  //const token = localStorage.getItem('storedCode');
  //onst tokenMsg = localStorage.getItem('storedMessage');
  //const ClubsList = localStorage.getItem('storedClubs');


  //const storedAccessJSON = localStorage.getItem('storedAccess');
  //console.log(storedAccessJSON)
  //const storedAccess = storedAccessJSON != "undefined" ? JSON.parse(storedAccessJSON) : [];


  //   async function loginUserAndFetchData(user,pass,lang) {
  //     let response = {
  //       result: false,
  //       errormessage: ''
  //     }

  //     try {
  //     const loginResponse = await fetch(`${ServerUrl}/SaveUser`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ User: user, Password: pass, Lang: lang })
  //     }).then(data => data.json());

  //     const fetchResponse = await fetch(`${ServerUrl}/api_Login`)
  //       .then(response2 => response2.json());

  //       let userAutologin = {
  //         username: user,
  //         password: pass,
  //         storedName: fetchResponse.NAME,
  //         storedCode: JSON.stringify(fetchResponse.MESSAGE_CODE),
  //         storedMessage: JSON.stringify(fetchResponse.MESSAGE).replace(/"/g, ''),
  //         storedClubs: fetchResponse.CLUB_LIST,
  //         storedAccess: fetchResponse.FEATURE_GRANTS_LIST,
  //         userlogged: false,
  //         rememberMe: false,
  //         language: lang
  //       }


  //     if (userAutologin.storedCode !== "0") {

  //       if (userAutologin.storedMessage !== "") {

  //         response.errormessage = userAutologin.storedMessage

  //       } else {

  //         response.errormessage = "Credentials not found"

  //       }

  //     } else {

  //       try {

  //         if (userAutologin.storedClubs.length > 0) {

  //           response.result = true
  //            return response

  //         } else {
  //           response.errormessage = "No access clubs"
  //         }

  //       } catch {
  //         response.errormessage = "No access clubs"
  //       }


  //     }

  //     return response;
  //   } catch(error)  {
  //     response.errormessage = error
  //     return response
  //   }
  // }


  useEffect(() => {

    if (loading === true) {


      const storageType = localStorage.getItem('storage')
      const storage = storageType === 'local' ? localStorage : sessionStorage;
      let curUser = storage.getItem('storedUsername');
      let curPass = storage.getItem('storedPassword');
      let curLang = localStorage.getItem('language');
      let curRemember = storage.getItem('remember');
      let storedName = storage.getItem('storedName');
      let storedClubs = storage.getItem('storedClubs');
      let storedAccess = storage.getItem('storedAccess');


      if (curUser == null || curUser == undefined) curUser = ""
      if (curPass == null || curPass == undefined) curPass = ""
      if (curLang == null || curLang == undefined) curLang = "CAT"
      if (curRemember == null || curRemember == undefined) curRemember = false
      if (storedName == null || storedName == undefined) storedName = ""
      if (storedClubs == null || storedClubs == undefined) storedClubs = ""
      if (storedAccess == null || storedAccess == undefined) storedAccess = ""




      if (curUser == "" || curPass == "" || curLang == "" || curRemember == false || storedName == "" || storedClubs == "" || storedAccess == "") {



        setuserLogged(false);
        localStorage.clear();
        sessionStorage.clear();

          localStorage.setItem('language',curLang)


      } else {

        setuserLogged(true);


      }

      // if (curRemember == "false") {
      //     setuserLogged(false)
      // }

      setLoading(false)
    }



  }, [ServerUrl]);
  console.log({ userLogged })

  if (loading) {
    return (
      <>Loading page ...</>
    )
  }


  if (!userLogged) {
    return (
      <LoginForm />
    )
  }

  // if (userLogged) {
  //     window.location.href = "/Desktop"
  //   // return (
  //   //   <>
  //   //   <div className="App">
  //   //       <HashRouter>
  //   //         <Switch>
  //   //           <Main>
  //   //             <Route exact path="/" component={Home} />
  //   //             <Route exact path="/dashboard">
  //   //               {storedAccess.map(item => item.FEATURE_CODE === "600") ? (
  //   //                 <Dashboard />
  //   //               ) : (
  //   //                 <Redirect to="/" />
  //   //               )}
  //   //             </Route>
  //   //             <Route exact path="/reservations" component={Reservations} />
  //   //             <Route exact path="/access-by-gender">
  //   //               {storedAccess.map(item => item.FEATURE_CODE === "601") ? (
  //   //                 <AccessByGender />
  //   //               ) : (
  //   //                 <Redirect to="/" />
  //   //               )}
  //   //             </Route>
  //   //             <Route exact path="/access-by-age">
  //   //               {storedAccess.map(item => item.FEATURE_CODE === "602") ? (
  //   //                 <AccessByAge />
  //   //               ) : (
  //   //                 <Redirect to="/" />
  //   //               )}
  //   //             </Route>
  //   //             <Route exact path="/pool-occupancy-page">
  //   //               {storedAccess.map(item => item.FEATURE_CODE === "603") ? (
  //   //                 <PoolOccupancyPage />
  //   //                 ) : (
  //   //                   <Redirect to="/" />
  //   //                 )}
  //   //             </Route>
  //   //             <Route exact path="/occupancy-directed-activities">
  //   //               {storedAccess.map(item => item.FEATURE_CODE === "604") ? (
  //   //                 <ActivitiesOccupancy />
  //   //                 ) : (
  //   //                   <Redirect to="/" />
  //   //                 )}
  //   //             </Route>
  //   //             <Route exact path="/profit-loses">
  //   //             {storedAccess.map(item => item.FEATURE_CODE === "606") ? (
  //   //                 <ProfitAndLoses />
  //   //                 ) : (
  //   //                   <Redirect to="/" />
  //   //                 )}
  //   //             </Route>
  //   //             <Route exact path="/pnl-desktop" component={PandLDesktop} />
  //   //             <Route exact path="/facturation" component={Facturation} />
  //   //             <Route exact path="/activ-clients" component={ActivClients} />
  //   //             <Route exact path="/new-contracts" component={NewContracts} />
  //   //             <Route exact path="/resiliations">
  //   //             {storedAccess.map(item => item.FEATURE_CODE === "607") ? (
  //   //                 <Resiliations />
  //   //                 ) : (
  //   //                   <Redirect to="/" />
  //   //                 )}
  //   //             </Route>
  //   //             <Route exact path="/cours-nage" component={CoursNage} />
  //   //             <Route exact path="/customer-balance">
  //   //             {storedAccess.map(item => item.FEATURE_CODE === "605") ? (
  //   //                 <CustomerBalance />
  //   //                 ) : (
  //   //                   <Redirect to="/" />
  //   //                 )}
  //   //             </Route>
  //   //             <Route exact path="/customer-balance-desktop" component={CustomerBalanceDsk} />
  //   //             <Route exact path="/logout" component={Logout} />
  //   //           </Main>
  //   //         </Switch>
  //   //       </HashRouter>
  //   //       </div>
  //   //       </>
  //   // )
  // }

  return (<>
    <div className="App">
      <HashRouter>
        <Switch>
          <Main>
            <Route exact path="/" component={Home} />
            <Route exact path="/dashboard">
              {storedAccess.map(item => item.FEATURE_CODE === "600") ? (
                <Dashboard />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/reservations" component={Reservations} />
            <Route exact path="/access-by-gender">
              {storedAccess.map(item => item.FEATURE_CODE === "601") ? (
                <AccessByGender />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/access-by-age">
              {storedAccess.map(item => item.FEATURE_CODE === "602") ? (
                <AccessByAge />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/pool-occupancy-page">
              {storedAccess.map(item => item.FEATURE_CODE === "603") ? (
                <PoolOccupancyPage />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/occupancy-directed-activities">
              {storedAccess.map(item => item.FEATURE_CODE === "604") ? (
                <ActivitiesOccupancy />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/profit-loses">
              {storedAccess.map(item => item.FEATURE_CODE === "606") ? (
                <ProfitAndLoses />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/pnl-desktop" component={PandLDesktop} />
            <Route exact path="/facturation" component={Facturation} />
            <Route exact path="/activ-clients" component={ActivClients} />
            <Route exact path="/new-contracts" component={NewContracts} />
            <Route exact path="/resiliations">
              {storedAccess.map(item => item.FEATURE_CODE === "607") ? (
                <Resiliations />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/cours-nage" component={CoursNage} />
            <Route exact path="/customer-balance">
              {storedAccess.map(item => item.FEATURE_CODE === "605") ? (
                <CustomerBalance />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/customer-balance-desktop" component={CustomerBalanceDsk} />
            <Route exact path="/logout" component={Logout} />
          </Main>
        </Switch>
      </HashRouter>
    </div>


  </>)

}

export default App;
