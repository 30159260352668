import React from 'react';

function Reservations() {
  

  return (
    <div>
      <h1>Reserves</h1>
    </div>
  );
}

export default Reservations;