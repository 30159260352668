import { useEffect, useState } from "react";


export default function useStorage() {
  const [store, setStore] = useState();
  useEffect(() => {
    const storage = localStorage.getItem('storage') === 'local' ? 'local' : 'session';
    setStore(storage === 'local' ? localStorage : sessionStorage);
  }, [])

  return store;
}