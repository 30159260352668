import React, { useEffect, useState, useContext } from "react";
import ToggleButton from 'react-toggle';
import 'react-toggle/style.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { UrlContext } from '../../context/UrlContext';

function ApiGender() {

  const [fetchedData, setFetchedData] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const [lineVisibility, setLineVisibility] = useState({
    TOT_MALE: true,
    TOT_FEMALE: true,
    TOT_NON_BINARY: true,
  });
  
  useEffect(() => {
    fetch(`${ServerUrl}/api_AccessByGender`)
    .then(response => response.json())
    .then(data => setFetchedData(data.ACCESS_LIST));
  },[ServerUrl]);

  return (
  <div className="genderContent">
  <div className="check-men">
  <ToggleButton
    id="toggle-men"
    onChange={() =>
      setLineVisibility((prevState) => ({
        ...prevState,
        TOT_MALE: !prevState.TOT_MALE,
      }))
    }
    checked={lineVisibility.TOT_MALE}
  />
  </div>
  
  <div className="check-women">
  <ToggleButton
    id="toggle-women"
    onChange={() =>
      setLineVisibility((prevState) => ({
        ...prevState,
        TOT_FEMALE: !prevState.TOT_FEMALE,
      }))
    }
    checked={lineVisibility.TOT_FEMALE}
  />
  </div>
  
  <div className="check-nobinary">
  <ToggleButton
    id="toggle-non-binary"
    onChange={() =>
      setLineVisibility((prevState) => ({
        ...prevState,
        TOT_NON_BINARY: !prevState.TOT_NON_BINARY,
      }))
    }
    checked={lineVisibility.TOT_NON_BINARY}
  />
  </div>

      <ResponsiveContainer height={400}>
        <LineChart
          data={fetchedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="PASS_HOUR" />
          <YAxis />
          <Tooltip />
          <Legend />
          
          <Line dataKey="TOT_MALE" name="MEN" fill="#043bd7" stroke="#043bd7" isAnimationActive={false} hide={!lineVisibility.TOT_MALE} />
          <Line dataKey="TOT_FEMALE" name="WOMEN" fill="#dbc108" stroke="#dbc108" isAnimationActive={false} hide={!lineVisibility.TOT_FEMALE} />
          <Line dataKey="TOT_NON_BINARY" name="NON BINARY" fill="#5dbb87" stroke="#5dbb87" isAnimationActive={false} hide={!lineVisibility.TOT_NON_BINARY} />
        </LineChart>
      </ResponsiveContainer>  
  </div>
  )
}

export default ApiGender