import { useState, useEffect, useContext } from "react";
import { UrlContext } from "../context/UrlContext";
import { useLanguage } from "../context/LanguageContext";
import "../assets/styles/loading.css";

function CustomerBalanceDsk() {
  const [fetchedCode, setFetchedCode] = useState("");
  const [fetchedMsg, setFetchedMsg] = useState("");
  const [fetchedTitle, setFetchedTitle] = useState("");
  const [fetchedDates, setFetchedDates] = useState("");
  const [fetchedRegToday, setFetchedRegToday] = useState("");
  const [fetchedTgtDrpt, setFetchedTgtDrpt] = useState("");
  const [fetchedTgtReg, setFetchedTgtReg] = useState("");
  const [fetchedTotCustomers, setFetchedTotCustomers] = useState("");
  const [fetchedTotClubs, setFetchedTotClubs] = useState("");
  const [fetchedExDrpt, setFetchedExDrpt] = useState("");
  const [fetchedTotRegFst, setFetchedTotRegFst] = useState("");
  const [fetchedTotReqDpt, setFetchedTotReqDpt] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const { /* language, */ translations } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${ServerUrl}/api_Customer_Balance?source=CustomerBalanceDsk`)
      .then(response => response.json())
      .then(data => {
        const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, RANGE_OF_DATES, TOT_REGISTRATIONS_TODAY, TOT_TARGET_DROPOUT, TOT_TARGET_REGISTRATIONS, TOT_TOTAL_CUSTOMERS, TOT_CLUBS, TOT_EXECUTED_DROPOUT, TOT_REGISTRATIONS_1ST, TOT_REQUEST_DROPOUT, BRAND_DATA_LIST } = data;
        setFetchedCode(MESSAGE_CODE);
        setFetchedMsg(MESSAGE);
        setFetchedTitle(PAGE_TITLE);
        setFetchedDates(RANGE_OF_DATES);
        setFetchedRegToday(TOT_REGISTRATIONS_TODAY);
        setFetchedTgtReg(TOT_TARGET_REGISTRATIONS);
        setFetchedTgtDrpt(TOT_TARGET_DROPOUT);
        setFetchedTotCustomers(TOT_TOTAL_CUSTOMERS);
        setFetchedTotClubs(TOT_CLUBS);
        setFetchedExDrpt(TOT_EXECUTED_DROPOUT);
        setFetchedTotRegFst(TOT_REGISTRATIONS_1ST);
        setFetchedTotReqDpt(TOT_REQUEST_DROPOUT);
        setFetchedData(BRAND_DATA_LIST);
        setIsLoading(false);
      });
  }, [ServerUrl]); 

  if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }
  else if ( fetchedCode === "0" ) {
    return (
     <div>
         <div className="pageTitle">
         <h1>{fetchedTitle}</h1>
         <p className="DatesRange">{fetchedDates}</p>
         </div>
         <div className="FixedHeaderTable">
         <table className="CustomerBalance">
         <thead>
                <tr>
                    <th>{translations.cbClubs}<br/>{fetchedTotClubs}</th>
                    <th>{translations.cbCustomers}<br/>{fetchedTotCustomers}</th>
                    <th>{translations.cbRegTgt}<br/>{fetchedTgtReg}</th>
                    <th>{translations.cbRegToday}<br/>{fetchedRegToday}</th>
                    <th>{translations.cbRegFromFirst}<br/>{fetchedTotRegFst}</th>
                    <th>{translations.cbReqDpt}<br/>{fetchedTotReqDpt}</th>
                    <th>{translations.cbExDpt}<br/>{fetchedExDrpt}</th>
                    <th>{translations.cbTgtDpt}<br/>{fetchedTgtDrpt}</th>
                </tr>
            </thead>


        <tbody>
        {fetchedData?.map((clubData, index) => (
          <tr key={index}>
            <td>{clubData.CLUB_NAME}</td>
            <td>{clubData.CUSTOMERS}</td>
            <td>{clubData.TARGET_REGISTRATIONS}</td>
            <td>{clubData.REGISTRATIONS_TODAY}</td>
            <td>{clubData.REGISTRATIONS_1ST}</td>
            <td>{clubData.DROPOUT_REQUEST}</td>
            <td>{clubData.EXECUTED_DROPOUT}</td>
            <td>{clubData.TARGET_DROPOUT}</td>
          </tr>
        ))}
      </tbody>

         </table>
         </div>
     </div>
    )
   }
   else {
     return (
     <>
     <h4>{fetchedMsg}</h4>
     </>
     )
   }
}

export default CustomerBalanceDsk