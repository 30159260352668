import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useLanguage } from "../../context/LanguageContext";
import useStorage from "../../hooks/useStorage";
import { useEffect, useState } from "react";

function Sidenav({ color, onMenuItemSelect }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { /* language, */ translations } = useLanguage();

  const storage = useStorage();
  const [storedAccess, setAccess] = useState([]);
  useEffect(() => {
    if(!storage) return;
    setAccess(JSON.parse(storage.getItem('storedAccess')) || [])
  },[storage])

  const handleMenuSelect = () => {
    // Appeler la fonction onMenuItemSelect transmise depuis le composant Main
    if (onMenuItemSelect) {
      onMenuItemSelect();
    }
    // Autres actions à effectuer lors de la sélection d'un élément de menu
    // ...
  }; 
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"></path>
    </svg>,
  ];

  const access = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={2}
    >
      <path fill="none" d="M7.197,16.963H7.195c-0.204,0-0.399-0.083-0.544-0.227l-6.039-6.082c-0.3-0.302-0.297-0.788,0.003-1.087
							C0.919,9.266,1.404,9.269,1.702,9.57l5.495,5.536L18.221,4.083c0.301-0.301,0.787-0.301,1.087,0c0.301,0.3,0.301,0.787,0,1.087
							L7.741,16.738C7.596,16.882,7.401,16.963,7.197,16.963z"></path>
    </svg>,
  ];  

  const resiliation = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={6}
    >
      <path d="M16.76,7.51l-5.199-5.196c-0.234-0.239-0.633-0.066-0.633,0.261v2.534c-0.267-0.035-0.575-0.063-0.881-0.063c-3.813,0-6.915,3.042-6.915,6.783c0,2.516,1.394,4.729,3.729,5.924c0.367,0.189,0.71-0.266,0.451-0.572c-0.678-0.793-1.008-1.645-1.008-2.602c0-2.348,1.93-4.258,4.303-4.258c0.108,0,0.215,0.003,0.321,0.011v2.634c0,0.326,0.398,0.5,0.633,0.262l5.199-5.193C16.906,7.891,16.906,7.652,16.76,7.51 M11.672,12.068V9.995c0-0.185-0.137-0.341-0.318-0.367c-0.219-0.032-0.49-0.05-0.747-0.05c-2.78,0-5.046,2.241-5.046,5c0,0.557,0.099,1.092,0.292,1.602c-1.261-1.111-1.979-2.656-1.979-4.352c0-3.331,2.77-6.041,6.172-6.041c0.438,0,0.886,0.067,1.184,0.123c0.231,0.043,0.441-0.134,0.441-0.366V3.472l4.301,4.3L11.672,12.068z"></path>
    </svg>,
  ];

  const Balance = [
    <svg xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20">
      <path d="M22.96423,13.82263a.94762.94762,0,0,0-.02819-.17419L20.63135,7.51135A2.99558,2.99558,0,0,0,22,5a1,1,0,0,0-2,0,1.00037,1.00037,0,0,1-1.88184.47266A2.8934,2.8934,0,0,0,15.54,4H13V3a1,1,0,0,0-2,0V4H8.46A2.8934,2.8934,0,0,0,5.88184,5.47266,1.00037,1.00037,0,0,1,4,5,1,1,0,0,0,2,5,2.99558,2.99558,0,0,0,3.36865,7.51135L1.064,13.64844a.94762.94762,0,0,0-.02819.17419A.94855.94855,0,0,0,1,14c0,.00928.00269.01782.00275.0271.0003.01318.003.02533.0039.03845a3.99379,3.99379,0,0,0,7.9867,0c.00085-.01312.0036-.02527.0039-.03845C8.99731,14.01782,9,14.00928,9,14a.94855.94855,0,0,0-.03577-.17737.94762.94762,0,0,0-.02819-.17419L6.62866,7.50421A2.98961,2.98961,0,0,0,7.64258,6.41992.917.917,0,0,1,8.46,6H11V20H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2H13V6h2.54a.917.917,0,0,1,.81738.41992,2.98961,2.98961,0,0,0,1.01392,1.08429L15.064,13.64844a.94762.94762,0,0,0-.02819.17419A.94855.94855,0,0,0,15,14c0,.00928.00269.01782.00275.0271.0003.01318.003.02533.0039.03845a3.99379,3.99379,0,0,0,7.9867,0c.00085-.01312.0036-.02527.0039-.03845C22.99731,14.01782,23,14.00928,23,14A.94855.94855,0,0,0,22.96423,13.82263ZM5,8.85553,6.5564,13H3.4436ZM6.72266,15A2.02306,2.02306,0,0,1,5,16a2.00023,2.00023,0,0,1-1.73145-1ZM19,8.85553,20.5564,13H17.4436ZM19,16a2.00023,2.00023,0,0,1-1.73145-1h3.45411A2.02306,2.02306,0,0,1,19,16Z"/>
    </svg>,
  ];

  const logout = [
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" key={8}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 4H13V9H11.5V5.5H5.5V18.5H11.5V15H13V20H4V4Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1332 11.25L15.3578 9.47463L16.4184 8.41397L20.0045 12L16.4184 15.586L15.3578 14.5254L17.1332 12.75H9V11.25H17.1332Z" fill="currentColor"/>
    </svg>,
  ];  

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
      <Menu.Item onClick={handleMenuSelect} key="0" id="999"> 
          <NavLink key={'super_key'} to="/">
            <span className="icon">{dashboard}</span>
            <span className="label">{translations.ParamSelector}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item id="600" onClick={handleMenuSelect} key="1">
        {storedAccess.some(item => item.FEATURE_CODE === "600") && (
          <NavLink key={'super_key'} to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        )}
        </Menu.Item>
        <Menu.Item onClick={handleMenuSelect} key="2" id="2">
          <NavLink key={'super_key1'} to="/access-clubs">
            <span className="icon">{access}</span>
            <span className="label">{translations.ClubOccupancy}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item id="601" onClick={handleMenuSelect} key="10">
        {storedAccess.some(item => item.FEATURE_CODE === "601") && (
          <NavLink key={'super_key2'} className="submenu" to="/access-by-gender">
            <span className="label">{translations.AccessByGender}</span>
          </NavLink>
        )}
        </Menu.Item>   
        <Menu.Item id="602" onClick={handleMenuSelect} key="11">
        {storedAccess.some(item => item.FEATURE_CODE === "602") && (
          <NavLink key={'super_key3'} className="submenu" to="/access-by-age">
            <span className="label">{translations.AccessByAge}</span>
          </NavLink>
        )}
        </Menu.Item>   
        <Menu.Item id="603" onClick={handleMenuSelect} key="12">
        {storedAccess.some(item => item.FEATURE_CODE === "603") && (
          <NavLink key={'super_key4'} className="submenu" to="/pool-occupancy-page">
            <span className="label">{translations.PoolOccupancy}</span>
          </NavLink>
        )}
        </Menu.Item>    
        <Menu.Item id="604" onClick={handleMenuSelect} key="13">
        {storedAccess.some(item => item.FEATURE_CODE === "604") && (
          <NavLink key={'super_key5'} className="submenu" to="/occupancy-directed-activities">
            <span className="label">{translations.ActivitiesOccupancy}</span>
          </NavLink>
        )}
        </Menu.Item>
        <Menu.Item onClick={handleMenuSelect} key="7" id="7">
          <NavLink key={'super_key6'} to="subscriptions">
            <span className="icon">{resiliation}</span>
            <span className="label">{translations.Terminacions}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item onClick={handleMenuSelect} id="607" key="14" >
        {storedAccess.some(item => item.FEATURE_CODE === "607") && (
          <NavLink key={'super_key7'} className="submenu" to="/resiliations">
            <span className="label">{translations.TerminacionsItems}</span>
          </NavLink>
        )}
        </Menu.Item> 
        <Menu.Item id="9999" onClick={handleMenuSelect} key="15" >
          <NavLink key={'super_key8'} to="/tracking">
            <span className="icon">{Balance}</span>
            <span className="label">{translations.Tracking}</span>
          </NavLink>
        </Menu.Item> 
        <Menu.Item id="605" onClick={handleMenuSelect} key="3">
        {storedAccess.some(item => item.FEATURE_CODE === "605") && (
          <NavLink key={'super_key9'} className="submenu" to="/customer-balance">
            <span className="label">{translations.CustomerBalance}</span>
          </NavLink>
        )}
        </Menu.Item>      
        <Menu.Item id="606" onClick={handleMenuSelect} key="4">
        {storedAccess.some(item => item.FEATURE_CODE === "606") && (
          <NavLink key={'super_key10'} className="submenu" to="/profit-loses">
            <span className="label">Profit and Loses - P&L</span>
          </NavLink>
        )}
        </Menu.Item>
        <Menu.Item onClick={handleMenuSelect} key="6" id="6">
          <NavLink key={'super_key11'} to="/logout">
            <span className="icon">{logout}</span>
            <span className="label">Logout</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
