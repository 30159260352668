import { useState,useEffect, useContext } from "react";
import ApiPoolOccupancy from '../apiAccess/ApiPoolOccupancy';
import { UrlContext } from "../../context/UrlContext";
import { useLanguage } from "../../context/LanguageContext";
import "../../assets/styles/loading.css";

function PoolOccupancy() {

  const [fetchedCode, setFetchedCode] = useState([]);
  const [fetchedMsg, setFetchedMsg] = useState([]);
  const [fetchedTitle, setFetchedTitle] = useState([]);
  const [fetchedClub, setFetchedClub] = useState([]);
  const [fetchedDates, setFetchedDates] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const { /* language, */ translations } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${ServerUrl}/api_PoolOccupancy`)
    .then(response => response.json())
    .then(data => {
      const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES } = data;
      setFetchedCode(MESSAGE_CODE);
      setFetchedMsg(MESSAGE);
      setFetchedTitle(PAGE_TITLE);
      setFetchedClub(CLUB_NAME);
      setFetchedDates(RANGE_OF_DATES);
      setIsLoading(false);
    }); 
  }, [ServerUrl]);  

  if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }
  else if ( fetchedCode === '0' ) { 
  return (
    <div>
      <div className="pageTitle">
        <h1>{fetchedTitle}</h1>
        <p className="ClubName">{fetchedClub}</p>
        <p className="DatesRange">{fetchedDates}</p>
      </div>
        <ApiPoolOccupancy />
    </div>
  )
  }
  else if ( fetchedCode !== '0' ) {
    return (
    <>
    <h4>{fetchedMsg}</h4>
    </>
    )
  }
}

export default PoolOccupancy