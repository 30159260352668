import React from 'react';

const Facturation = () => {
    return (
        <div>
            <h1>Facturació</h1>
        </div>
    );
};

export default Facturation;