import React, { useState } from 'react';
import PoolOccupancy from '../components/occupancy/PoolOccupancy';
import PoolActivities from '../components/occupancy/PoolActivities';

function PoolOccupancyPage() {

  const [showOccupancy, setShowOccupancy] = useState(true);

  const handleShowOccupancy = () => {
    setShowOccupancy(true);
  };
  
  const handleShowActivities = () => {
    setShowOccupancy(false);
  };
    
  return (
    <div>
      <div className='blueButtons pool'>
      <button onClick={handleShowOccupancy}>Bany lliure</button>
      <button onClick={handleShowActivities}>Activitats</button>
      </div>  
      {showOccupancy ? <PoolOccupancy /> : <PoolActivities />}
    </div>
  );
}

export default PoolOccupancyPage