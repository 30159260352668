import React, {useState, useContext, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/fr';
import { UrlContext } from '../context/UrlContext';
import { useLanguage } from "../context/LanguageContext";

registerLocale('en', en);

function DatesSelection() {

  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const [formattedStartDate, setFormattedStartDate] = useState(
    localStorage.getItem('startDate') || formattedToday
  );
  const [formattedEndDate, setFormattedEndDate] = useState(
    localStorage.getItem('endDate') || formattedToday
  );

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const ServerUrl = useContext(UrlContext);  
  const { /* language, */ translations } = useLanguage();

  useEffect(() => {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');
    if (storedStartDate) {
      setStartDate(new Date(storedStartDate));
    }
    if (storedEndDate) {
      setEndDate(new Date(storedEndDate));
    }
  }, []);

  const handleStartChange = (start) => {
    setStartDate(start);
    const formattedStart = start ? formatDate(start) : formattedToday;
    setFormattedStartDate(formattedStart);
    localStorage.setItem('startDate', formattedStart);
  };

  const handleEndChange = (end) => {
    setEndDate(end);
    const formattedEnd = end ? formatDate(end) : formattedToday;
    setFormattedEndDate(formattedEnd);
    localStorage.setItem('endDate', formattedEnd);
  };

  const handleLastWeekClick = () => {
    const lastWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const lastWeekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

    setStartDate(lastWeekStartDate);
    setEndDate(lastWeekEndDate);

    const formattedStart = formatDate(lastWeekStartDate);
    setFormattedStartDate(formattedStart);
    const formattedEnd = formatDate(lastWeekEndDate);
    setFormattedEndDate(formattedEnd);

    localStorage.setItem('startDate', formattedStart);
    localStorage.setItem('endDate', formattedEnd);
  };

  const handleLastMonthClick = () => {
    const today = new Date();
    const lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

    setStartDate(lastMonthStartDate);
    setEndDate(lastMonthEndDate);

    const formattedStart = formatDate(lastMonthStartDate);
    setFormattedStartDate(formattedStart);
    const formattedEnd = formatDate(lastMonthEndDate);
    setFormattedEndDate(formattedEnd);

    localStorage.setItem('startDate', formattedStart);
    localStorage.setItem('endDate', formattedEnd);
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
    async function useSelectedDates() {
      const dataDates = await fetch(`${ServerUrl}/SelectedDates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ SelectedStartDate: formattedStartDate, SelectedEndDate: formattedEndDate })
      });
      return await dataDates.json();  
    };  

  return (
    useSelectedDates(),
	<div>
      <h4>{translations.custom}</h4>
      <h4>{translations.StartDate}</h4>
      <DatePicker
        selected={startDate}
        onChange={handleStartChange}
        dateFormat="dd-MM-yyyy"
        shouldCloseOnSelect={true}
        placeholderText={startDate ? formatDate(startDate) : formattedStartDate}
        locale="en"
      />
      <h4>{translations.EndDate}</h4>
      <DatePicker
        selected={endDate}
        onChange={handleEndChange}
        dateFormat="dd-MM-yyyy"
        shouldCloseOnSelect={true}
        placeholderText={endDate ? formatDate(endDate) : formattedEndDate}
        locale="en"
      />
      <div className='blueButtons'>
        <button onClick={handleLastWeekClick}>{translations.LastWeek}</button>
        <button onClick={handleLastMonthClick}>{translations.LastMonth}</button>
      </div>
    </div>
  );
}

export default DatesSelection;
